import clsx from 'clsx';
import Image from 'components/common/Image';
import LinkComponent from 'components/common/Link';
import { rem } from 'lib';
import { ProductProps } from 'interfaces/cms/content';
import ButtonComponent from 'components/common/Button';
import Markdown from 'components/common/Markdown';
import styles from '../ProductOverview.module.scss';

function BigProductCard({
  id,
  url,
  alt,
  title,
  subTitle,
  linkUrl,
  linkSlug,
  button,
}: ProductProps) {
  const isNoLink = !linkSlug && !linkUrl;
  return (
    <div
      key={id}
      className={clsx(
        styles.productOverview__section,
        styles.productOverview__sectionBig
      )}
    >
      <LinkComponent
        href={linkSlug || linkUrl || ''}
        rel={button.buttonLinkRel}
        isExternalLink={!!linkUrl}
      >
        <div className="p-[1.25rem] md:p-[1.875rem]">
          <div
            className={clsx(
              'flex flex-wrap flex-row items-start',
              styles.productOverview__image
            )}
          >
            <Image alt={alt} src={url} width={195} height={146} />
          </div>
          <div
            className={clsx(
              'pt-[0.625rem] lg:pt-[1.25rem] text-center',
              styles.ProductOverview__cardTitle
            )}
          >
            <span className={styles.productOverview__cardTitleBig}>
              <Markdown
                md={title}
                textAlign="center"
                fontSize={rem(18)}
                textColor="#4f4b66"
              />
            </span>
          </div>
          <div
            className={clsx(
              'hidden md:block',
              styles.productOverview__subtitle
            )}
          >
            <h5 className="text-center text-textColor">
              <Markdown md={subTitle} textAlign="center" fontSize={rem(14)} />
            </h5>
          </div>

          {!isNoLink && (
            <div
              className={clsx(
                'w-full hidden md:flex self-center pt-[1.25rem]',
                styles.productOverview__linkBtn
              )}
            >
              <div className={styles.productOverview__bigButton}>
                <ButtonComponent {...button} />
              </div>
            </div>
          )}
        </div>
      </LinkComponent>
    </div>
  );
}

export default BigProductCard;
